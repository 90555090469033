<template>
  <div>
    <b-card header="Add Organizer">
      <event-organizer-form
        :submit="addOrganizer"
        :organizer.sync="organizer"
      />
      <upload-progress :progress="progress" />
    </b-card>
  </div>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import EventOrganizerForm from '@/common/components/EventOrganizers/EventOrganizerForm.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'

export default {
  name: 'AddEventOrganizer',
  components: {
    EventOrganizerForm,
    UploadProgress,
  },
  data() {
    return {
      organizer: {
        location: {},
        logo: [],
      },
    }
  },
  computed: {
    formattedOrganizer() {
      return {
        name: this.organizer.name,
        first_name: this.organizer.first_name,
        last_name: this.organizer.last_name,
        phone_number: this.organizer.phone_number,
        email: this.organizer.email,
        about: this.organizer.about,
        social_facebook: this.organizer.social_facebook,
        social_youtube: this.organizer.social_youtube,
        logo: this.organizer.logo[0],
        ...this.organizer.location,
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { progress, calculateUploadPercentage } = uploadPercentage()
    return {
      progress, calculateUploadPercentage, successfulOperationAlert,
    }
  },
  methods: {
    addOrganizer() {
      return this.$entities.post('internalops/organizer', this.formattedOrganizer, {
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      }).then(() => {
        this.successfulOperationAlert('Organizer is added successfully')
        this.$router.push({ name: 'event-organizers-list' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
