<template>
  <b-progress
    v-if="progress"
    max="100"
    class="progress-bar-success my-1"
  >
    <b-progress-bar
      :value="progress"
      :label="`${progress}%`"
      variant="success"
    />
  </b-progress>
</template>
<script>
export default {
  name: 'UploadProgress',
  props: {
    progress: { type: Number, default: 0 },
  },
}
</script>
<style lang="scss">

</style>
