<template>
  <div class="p-4">
    <add-organizer />
  </div>
</template>
<script>
import AddOrganizer from '@/views/event-organizers/add.vue'

export default {
  name: 'RegisterOrganizer',
  components: {
    AddOrganizer,
  },
}
</script>
<style lang="">

</style>
