import { ref } from '@vue/composition-api'

export default function uploadPercentage() {
  const progress = ref(0)
  const calculateUploadPercentage = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    progress.value = percentCompleted
  }

  return {
    progress,
    calculateUploadPercentage,
  }
}
